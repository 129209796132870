import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'ramda';
import { graphql } from 'gatsby';
import Section from '../components/common/section';
import Introduction from '../components/dashboardPage/introduction';
import {
  DARK_SCHEME, LIGHT_GRAY_1, WHITE,
} from '../constants/colors';
import { media } from '../constants/screens';
import { groupItemsByCategory } from '../utils/keystone/data';
import withCustomLayout from '../enhancers/withCustomLayout';
import withStickyHeader from '../enhancers/withStickyHeader';
import withChildRoutes from '../enhancers/withChildRoutes';
import BrochureRequest from '../components/brochureRequestPage';
import HiddenBrochureRequestForm from '../components/brochureRequestPage/brochureRequestHiddenForm';
import Questions from '../components/common/questions';
import ItemGroups from '../components/common/itemGroups';
import Categories from '../components/common/categories';
import ImageSection from '../components/common/imageSection';
import withScroll from '../enhancers/withScroll';
import withClass from '../enhancers/withClass';

const Dashboards = (props) => {
  const {
    data: {
      page: { introduction, sections = [] },
      categories: { edges: categories },
      dashboards: { edges: dashboards },
      brochureRequestFormHidden,
    },
    scrollTo,
  } = props;

  const questions = sections.filter(({ meta }) => meta && meta.includes('questions'));
  const imageSection = sections.filter(({ meta }) => meta && meta.includes('image'));

  return (
    <div>
      <Introduction
        {...introduction}
        scrollTo={scrollTo}
      />
      {imageSection && (
        <ImageSection
          bg={LIGHT_GRAY_1}
          {...imageSection[0]}
        />
      )}
      {questions && (
        <Questions
          {...questions[0]}
          bg={WHITE}
          css={`padding-bottom: 100px; ${media.tablet`padding-bottom: 0;`}`}
        />
      )}
      {sections[2] && (
        <Section
          {...sections[2]}
          bg={WHITE}
          css={`padding-bottom: 100px; ${media.tablet`padding-bottom: 0;`}`}
        />
      )}
      {sections[3] && (
        <Section
          {...sections[3]}
          bg={WHITE}
          align="center"
          css="padding-bottom: 0;"
        />
      )}
      <Categories categories={categories} />
      <ItemGroups groups={groupItemsByCategory(categories, dashboards)} />
      <HiddenBrochureRequestForm name="request-brochure" formData={brochureRequestFormHidden} />
    </div>
  );
};

Dashboards.propTypes = {
  data: PropTypes.shape().isRequired,
  scrollTo: PropTypes.func.isRequired,
};

export default compose(
  withChildRoutes({
    defaultRoute: '/data-dashboards',
    childRoutes: [
      { path: '/data-dashboards/:slug', component: BrochureRequest },
    ],
  }),
  withStickyHeader(),
  withCustomLayout({ scheme: DARK_SCHEME }),
  withScroll({
    sections: {
      subscriptions: [4, 1],
    },
  }),
  withClass,
)(Dashboards);

// eslint-disable-next-line
export const query = graphql`
  query DashboardsPageQuery {
    brochureRequestFormHidden: form(name: { regex: "/request/" }) {
      subTitle
      recaptcha
      alert {
        type
        message
      }
      privacy {
        md
      }
      formFields {
        id: _id
        required
        htmlName
        selectOptions {
          id: _id
          value
          label
        }
        label {
          md
          html
        }
        type
      }
      emailMe {
        required
        htmlName
        label {
          md
          html
        }
        type
      }
      submitLabel
      requiredLabel
    }
    page(url: { regex: "/data-dashboards/" }) {
      introduction {
        id: _id
        title
        subTitle
        button {
          url
          text
        }
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 800) { 
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
      sections {
        title
        description
        meta
        content {
          id: _id
          description {
            md
          }
        }
        image {
          alt
          file {
            localFile {
              childImageSharp {
                sizes(maxWidth: 670) {
                  ...GatsbyImageSharpSizes_noBase64
                }
                fluid(maxWidth: 1000, quality: 100) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
    dataDashboards: allDashboard {
      edges {
        node {
          id
          title
          subtitle
          slug
          meta {
            description
          }
          publishedAt
          categories {
            _id
          }
          cover {
            localFile {
              childImageSharp {
                sizes(maxWidth: 500) {
                  ...GatsbyImageSharpSizes_noBase64
                }
              }
            }
          }
        }
      }
    }
    categories: allDashboardCategory {
      edges {
        node {
          id: _id
          name
          slug
          image {
            file {
              localFile {
                publicURL
              }
            }
          }
          description {
            md
          }
        }
      }
    }
    dashboards: allDashboard(sort: { fields: [ publishedAt ], order: [ DESC ] }) {
      edges {
        node {
          id
          title
          subtitle
          slug
          excerpt
          description {
            md
          }
          publishedAt(formatString: "MMMM YYYY")
          categories {
            id: _id
            name
            slug
          }
          cover {
            localFile {
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`;
