import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AboutUsItem from '../common/aboutUsItem';
import Info, { ButtonWrapper } from '../common/info';
import { CONTAINER_LAPTOP_WIDTH, CONTAINER_WIDTH, media } from '../../constants/screens';
import { BLACK, BLUE, DARK_GRAY } from '../../constants/colors';

const Introduction = ({
  image: { alt, file: { localFile: { childImageSharp: { sizes } } } },
  title, subTitle, button: { text, url },
  scrollTo,
}) => (
  <Container>
    <Content>
      <AboutUsItem
        image={sizes}
        maxImageWidth="880px"
        imgAltAttr={alt}
      >
        <Info
          externalLink
          withButton
          title={subTitle}
          subTitle={title}
          titleColor={DARK_GRAY}
          subTitleColor={BLUE}
          textColor={BLACK}
          subTitleFontSize="40px"
          buttonText={text}
          link={url}
          buttonColor={BLUE}
          pageName="data-dashboards"
          onButtonClick={() => {
            setTimeout(() => scrollTo('subscriptions'), 300);
          }}
        />
      </AboutUsItem>
    </Content>
  </Container>
);

Introduction.propTypes = {
  image: PropTypes.shape(),
  title: PropTypes.string,
  subTitle: PropTypes.string,
  button: PropTypes.shape(),
  scrollTo: PropTypes.func.isRequired,
};

Introduction.defaultProps = {
  image: { file: { localFile: { childImageSharp: { sizes: {} } } } },
  title: '',
  subTitle: '',
  button: {
    url: '',
    text: '',
  },
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 300px 5% 120px 5%;
  
  ${media.tablet`
    padding: 200px 5% 120px 5%;
  `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  max-width:  ${CONTAINER_WIDTH};
  width: 100%;
  align-items: center;
  justify-content: center;
  
  > div {
    width: 100%;
  }
  
  ${media.laptop`
    max-width: ${CONTAINER_LAPTOP_WIDTH};
  `};
  
  ${media.tablet`
    flex-direction: column;
    align-items: center;
  `}
  
  & ${ButtonWrapper} {
    margin-top: 20px;
    
    ${media.tablet`
       padding: 0;
       margin-top: 0px;
    `}
    
    ${media.mobile`
       padding: 0;
       margin-top: 0px;
    `}
  }
`;

export default Introduction;
