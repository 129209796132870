import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

/**
 * This form is created to register fields on netlify.
 * Brochure request from is rendered by JS
 * so you need to include this form in any static page.
 * All inputs should have same name with brochure request form
 */
const HiddenForm = ({ name, formData }) => (
  <Form
    name={name}
    method="post"
    data-netlify="true"
    data-netlify-recaptcha="true"
  >
    {formData.formFields.map(field => (
      <input name={field.htmlName} key={field.id} type="hidden" />
    ))}
    {formData.emailMe && (
      <input type="hidden" name={formData.emailMe.htmlName} />
    )}
    <input type="hidden" name="brochureUrl" />
    <input type="hidden" name="brochureTitle" />
    <input type="hidden" name="form-name" value="request-brochure" />
  </Form>
);

HiddenForm.propTypes = {
  name: PropTypes.string,
  formData: PropTypes.shape().isRequired,
};

HiddenForm.defaultProps = {
  name: 'request-free-report',
};

const Form = styled.form`
  position: absolute;
  overflow: hidden;
  width: 0;
  height: 0;
  visibility: hidden;
`;

export default HiddenForm;
